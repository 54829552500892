import Api from './Api.js';
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  addDepartment(payload) {
    return Api().post(`/v2/department?organization_id=${organization_id()}`, payload);
  },
  getAllDepartmentsByCompany(company_id) {
    return Api().get(`/v2/department/company/${company_id}?organization_id=${organization_id()}`);

  },

  editDepartment(payload, id) {
    return Api().put(`/v2/department/${id}?organization_id=${organization_id()}`, payload);
  },

  getAllDepartments(filters, sort) {
    if (filters && filters.hasOwnProperty('archive') && filters.archive) {
      return Api().get(`/v2/department?archive=1&organization_id=${organization_id()}`);
    } else if (filters && filters.hasOwnProperty('page')) {
      return Api().get(`/v2/department/page/${filters.page}?organization_id=${organization_id()}`);
    } else return Api().get(`/v2/department?organization_id=${organization_id()}`);
  },

  getDepartment(id, archive) {
    if (!archive) {
      return Api().get(`/v2/department/${id}?organization_id=${organization_id()}`);
    } else {
      return Api().get(`/v2/department/${id}?${archive}&organization_id=${organization_id()}`);
    }
  },


};
