<template>
  <div>
    <!-- ADD DEPARTMENT FORM  -->
    <div class="vx-row">
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full">
        <vx-card class="form-title" title="Add Department">
          <form>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <div class="vx-col md:w-3/4 mx-auto">
                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-right align-self-center"
                  >
                    <h6 class="mb-0">Company*</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div>
                      <multiselect
                        disabled
                        v-model="value"
                        track-by="company_id"
                        label="company_name"
                        @input="
                          ({ company_id }) =>
                            (this.form.company_id = company_id)
                        "
                        :options="companies"
                        name="Company Name"
                        :searchable="true"
                        :allow-empty="false"
                        open-direction="bottom"
                        deselect-label="Can't remove this value"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          {{ option.company_name }}
                        </template>
                      </multiselect>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Company Name')"
                        >{{ errors.first("Company Name") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-right align-self-center"
                  >
                    <h6 class="mb-0">Department Name*</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div class>
                      <vs-input
                        v-validate="{
                          required: true,
                          regex: /^[A-Za-z0-9 ]+$/,
                        }"
                        name="Department Name"
                        v-model="form.department_name"
                        class="w-full"
                        type="text"
                        rules="alpha"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Department Name')"
                        >{{ errors.first("Department Name") }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full text-right">
                    <h6 class="mb-0">Description</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <vs-textarea v-model="description" class="mb-0" rows="4" />
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full text-right">
                    <h6 class="mb-0">Status</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <b-form-checkbox
                      value="1"
                      unchecked-value="2"
                      v-model="form.department_active"
                      name="check-button"
                      switch
                    ></b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3"
                  >Submit</vs-button
                >

                <vs-button
                  color="warning"
                  type="border"
                  class
                  @click.prevent="clearForm"
                  >Reset</vs-button
                >
              </div>
              <div class="vx-col">
                <div class="excel-btn-1">
                  <vx-tooltip text="Template Excel" position="left">
                    <vs-button
                      @click.native="
                        onClickSampleDownload(
                          excelurl +
                            `/v2/department/excel/sample?organization_id=${organization_id}`
                        )
                      "
                      type="border"
                      class="py-2"
                    >
                      <img
                        class="d-inline-flex"
                        src="@/assets/images/icons/excel.svg"
                        height="20"
                        width="20"
                      />
                    </vs-button>
                  </vx-tooltip>
                </div>

                <div class="excel-btn-2">
                  <vx-tooltip text="Import Excel" position="left">
                    <label for="import-file">
                      <input
                        ref="uploadExcelFile"
                        @change="uploadExcel($event)"
                        type="file"
                        name="import-file"
                        id="import-file"
                        class="d-none"
                      />
                      <div
                        class="
                          vs-component vs-button
                          px-4
                          vs-button-primary vs-button-filled
                        "
                      >
                        <span class="d-inline-flex pr-5 text-white"
                          >Import</span
                        >
                        <img
                          class="d-inline-flex"
                          src="@/assets/images/icons/import-excel.svg"
                          height="20"
                          width="20"
                        />
                      </div>
                    </label>
                  </vx-tooltip>
                </div>
              </div>
            </div>
          </form>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import CompanyService from "@/services/companyService.js";
import DepartmentService from "@/services/departmentService.js";
import uploadService from "@/services/uploadService.js";
import { BASEURL } from "@/config/index.js";
import axios from "axios";
import { TokenService } from "@/services/storage.service.js";

const VxTour = () => import("@/components/VxTour.vue");

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
  },

  data() {
    return {
      excelurl: BASEURL(),
      submitStatus: true,
      User: "Select User",
      description: "",
      form: {
        department_name: "",
        company_id: "",
        department_active: "1",
      },
      companies: [],
      value: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      contacts: contacts,
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
    form: {
      handler: function (newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    organization_id() {
      return localStorage.getItem("OrganizationID");
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    onClickSampleDownload: function (URL) {
      window.open(URL, "_blank");
    },
    uploadExcel: function (event) {
      let that = this;
      that.$vs.loading();
      let formData = new FormData();
      formData.append("file", event.target.files[0]);
      axios
        .post(
          that.excelurl +
            `/v2/department/import/excel?organization_id=${this.organization_id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          const { data } = response;
          const input = this.$refs.uploadExcelFile;
          input.type = "text";
          input.type = "file";
          if (
            data &&
            data["error"] &&
            data.error["message"] &&
            data.error.message.length > 0
          ) {
            that.$vs.notify({
              text: data.error.message || "Error",
              iconPack: "feather",
              time: 35000,
              icon: "check_box",
              color: "warning",
            });
          } else {
            that.$vs.notify({
              title: "Updated!",
              text: data.data.message || "Updated",
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
          }
          that.$vs.loading.close();
          eventBus.$emit("refreshTabledepa", data);
        })
        .catch((error) => {
          that.$vs.loading.close();
          that.$vs.notify({
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();

          this.submitStatus = false;
          let payload = {
            department_name: this.form.department_name,
            company_id: this.form.company_id,
            description: this.description,
            department_active: this.form.department_active,
          };
          DepartmentService.addDepartment(payload)
            .then((response) => {
              this.submitStatus = false;
              const { data } = response;
              this.$vs.loading.close();
              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Created!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                eventBus.$emit("refreshTabledepa", data);
                this.clearForm();
              } else {
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.submitStatus = true;
            });
        }
      });
    },
    getCompanyList: function () {
      CompanyService.getAllCompanies()
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
          } else {
            this.companies = data.data;
            if (this.companies.length > 0) {
              this.value = this.companies[0];
              this.form.company_id = this.value.company_id;
            }
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    clearForm: function () {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.description = "";
      this.value = [];
      this.$validator.reset();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
  },
  mounted() {
    this.getCompanyList();
    this.gridApi = this.gridOptions.api;
  },
};
</script>
