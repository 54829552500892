<template>
  <div>
    <span class="text-center table-edit-icon" @click="getPreview()">
      <i class="text-center material-icons">remove_red_eye</i>
    </span>
    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalView'+params.data.department_id"
        class="holamundo p-2"
        title="Department Details"
        :active.sync="popupActive"
      >
        <div class="vx-row">
          <!-- LEFT SIDE -->
          <div class="vx-col w-full">
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/4 w-full text-right align-self-center">
                <h5 class="mb-0">Name:</h5>
              </div>
              <div class="vx-col sm:w-3/4 w-full">
                <div class>
                  <span>{{ departmentDetail.department_name}}</span>
                </div>
              </div>
            </div>

            <!-- ********************** -->
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/4 w-full text-right align-self-center">
                <h5 class="mb-0">Company:</h5>
              </div>
              <div class="vx-col sm:w-3/4 w-full">
                <div class>
                  <span>{{ departmentDetail.company_name}}</span>
                </div>
              </div>
            </div>

            <!-- ********************** -->

            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/4 w-full text-right">
                <h5 class="mb-0">Description:</h5>
              </div>
              <div class="vx-col sm:w-3/4 w-full">
                <span>{{ departmentDetail.description}}</span>
              </div>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DepartmentService from "@/services/departmentService";
export default Vue.extend({
  data() {
    return {
      params: null,
      departmentDetail: {},
      popupActive: false,
      //   project_id: ""
    };
  },

  beforeMount: function() {
    this.department_id = this.params.value;
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalView" + this.params.data.department_id,
    );
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    getPreview: function() {
      this.popupActive = true;
      DepartmentService.getDepartment(
        this.department_id,
        String(this.params.data.department_active) === "4"
          ? "archive"
          : undefined,
      )
        .then(response => {
          const { data } = response;
          if (!data.err||data.Status == true ) {
            this.departmentDetail = data.data;
          } else {
          }
        })
        .catch(error => {
          console.log("error :", error);
        });
    },
  },
});
</script>

<style>
</style>